import React from 'react';

export default ({ intl }) => [{
  imagem: 'teste-e1563385548278.png',
  titulo: intl.formatMessage({ id: 'Atualização Online' }),
  descricao: (
    <>
      {intl.formatMessage({ id: 'Fácil e rápido, funciona offline' })}, <br /> {intl.formatMessage({ id: 'vista explodida' })}.
    </>
  )
}, {
  imagem: 'icone-2-e1563385569569.png',
  titulo: intl.formatMessage({ id: 'Layout Exclusivo' }),
  descricao: (
    <>
      {intl.formatMessage({ id: 'Recursos de marketing' })}, <br /> {intl.formatMessage({ id: 'até 4 idiomas' })}.
    </>
  )
}, {
  imagem: 'carrinho-2-e1563385585585.png',
  titulo: intl.formatMessage({ id: 'Carrinho de compras' }),
  descricao: (
    <>
      {intl.formatMessage({ id: 'Política de preço por cliente, Estatística de acesso, Administração por sua empresa' })}.
    </>
  )
}];
