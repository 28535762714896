import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Helmet } from 'react-helmet';
import { useIntl, Link } from 'gatsby-plugin-intl';
import Layout from '../components/layout';
import CustomCarouselHome from '../components/CustomCarouselHome';
import getConfigDestaquesCatalogo from '../data/configDestaquesCatalogo';
import getConfigQualidadesCatalogo from '../data/configQualidadesCatalogo';
import configTextoEmpresa from '../data/configTextoEmpresa';

export default ({ location, data }) => {
  const intl = useIntl();

  const file = data.imagemSobreEmpresa.nodes.find((a) => `${a.name}.${a.extension}` === 'imagem-empresa02.jpg');
  const ImgEmpresa02 = file.childImageSharp;

  const configDestaquesCatalogo = getConfigDestaquesCatalogo({ intl });
  const configQualidadesCatalogo = getConfigQualidadesCatalogo({ intl });

  return (
    <Layout location={location}>
      <Helmet bodyAttributes={{ class: 'home page-template page-template-page-home page-template-page-home-php page page-id-24 full-width custom-font-enabled single-author mob-menu-slideout' }}>
        <title>{intl.formatMessage({ id: 'Ideia 2001 | Catálogos Inteligentes' })}</title>
      </Helmet>

      <div id="primary" className="site-content">
        <div id="content" role="main">
          <CustomCarouselHome />

          <section>
            <div className="container">
              <div className="row mt-4">
                <div className="col-xl-5 col-lg-4 col-md-12 boxAmarelo">
                  <h2 className="tt-u">{intl.formatMessage({ id: 'Inovação e' })} <em>{intl.formatMessage({ id: 'tecnologia' })}</em></h2>
                  <h3>{intl.formatMessage({ id: 'Pra você e para os seus clientes' })}</h3>
                  <Link to="/catalogo-expresso-instalado" className="maisVantagens mt-5 tt-u">
                    {intl.formatMessage({ id: 'Mais vantagens' })}
                  </Link>
                </div>
                <div className="col-xl-7 col-lg-8 col-md-12 inoTec mt-5">
                  <h3 className="tt-u">{intl.formatMessage({ id: 'Catálogo expresso' })}: <span>{intl.formatMessage({ id: 'O que é?' })}</span></h3>
                  <p>
                    {intl.formatMessage({ id: 'É um catálogo eletrônico para demonstração de produtos' })}.
                    <br />
                    {intl.formatMessage({ id: 'O seu alto desempenho e a sua facilidade de uso tornam mais rápido e fácil' })}
                    <br />
                    {intl.formatMessage({ id: 'o encontro de seus produtos por seus clientes' })}.
                  </p>
                  {configDestaquesCatalogo && configDestaquesCatalogo.length ? (
                    <ul className="row mt-5">
                      {
                        configDestaquesCatalogo.map((config) => {
                          let file;
                          if (config.imagem === 'teste-e1563385548278.png') {
                            file = data.imagensDestaquesCatalogos54x60.nodes.find((a) => `${a.name}.${a.extension}` === config.imagem);
                          } else {
                            file = data.imagensDestaquesCatalogos60x54.nodes.find((a) => `${a.name}.${a.extension}` === config.imagem);
                          }
                          const { childImageSharp } = file;

                          return (
                            <li className="col-lg-4 col-md-12 text-center detasquesCat" key={config.titulo}>
                              <Img fixed={childImageSharp.fixed} alt={config.titulo} />
                              <h4 className="tt-u">{config.titulo}</h4>
                              <div className="valor">{config.descricao}</div>
                            </li>
                          );
                        })
                      }
                    </ul>
                  ) : null}
                </div>
              </div>
            </div>
          </section>

          <section className="fundoBranco pt-4">
            <div className="container mt-100">
              <div className="row textoFerramentas">
                <div className="col-12 tt-u text-center">
                  <strong>{intl.formatMessage({ id: 'Desenvolvido para o mercado de autopeças e peças em geral' })},</strong> {intl.formatMessage({ id: 'atendendo fabricantes e distribuidores' })}
                </div>
                <div className="col-lg-5 col-md-12">
                  {configQualidadesCatalogo && configQualidadesCatalogo.length ? (
                    <ul className="row qualidade">
                      {configQualidadesCatalogo.map((config) => (
                        <li className="col-12" key={config.qualidade}>
                          <h2>{config.qualidade}</h2>
                        </li>
                      ))}
                    </ul>
                  ) : null}
                </div>
                <div className="col-lg-7 col-md-12"></div>
              </div>
            </div>
          </section>

          <section className="fundoBranco pt-4 pt-100">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-12 textoEmpresa">
                  <div className="tituloEmpresa">{intl.formatMessage({ id: 'Empresa' })}</div>
                  {configTextoEmpresa}
                </div>
                <div className="col-lg-1 col-md-12 some"></div>
                <div className="col-lg-5 col-md-12 imagemEmpresaHome">
                  <h2 className="tituloSobreEmpresa">{intl.formatMessage({ id: 'Sobre a empresa' })}</h2>
                  <div className="containerImagemSobreEmpresa">
                    <Img fluid={ImgEmpresa02.fluid} alt={intl.formatMessage({ id: 'Ideia 2001 - Catálogos Inteligentes' })} />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    imagemSobreEmpresa: allFile(filter: {relativePath: {in: ["imagem-empresa02.jpg"]}}) {
      nodes {
        name
        extension
        childImageSharp {
          fluid(maxWidth: 1396, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
    imagensDestaquesCatalogos54x60: allFile(filter: {relativePath: {in: 
      ["teste-e1563385548278.png"]
    }}) {
      nodes {
        name
        extension
        childImageSharp {
          fixed(width: 54, height: 60, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
    imagensDestaquesCatalogos60x54: allFile(filter: {relativePath: {in: 
      ["icone-2-e1563385569569.png", "carrinho-2-e1563385585585.png"]
    }}) {
      nodes {
        name
        extension
        childImageSharp {
          fixed(width: 60, height: 54, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  }
`;
