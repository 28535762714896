import React from 'react';
const ImgCatalogoInstalado = 'catalogos-instalados-ideia-2001-2-e1563473327620.png';
const ImgCatalogoMobile = 'app-ideia-5-e1563481442645.png';
const ImgCatalogoImpresso = 'impresso-3-e1563476481137.png';
const ImgDesenhosTecnicos = 'desenhos-tecnicos-home.png';

export default ({ intl }) => [{
  index: 0,
  imgSrc: ImgCatalogoInstalado,
  tituloCatalogo: intl.formatMessage({ id: 'CATÁLOGO' }),
  subtituloCatalogo: intl.formatMessage({ id: 'Expresso Instalado' }),
  tituloTextoSlide: intl.formatMessage({ id: 'conheça nossas soluções para seu negócio' }),
  textoSlide: (
    <>
      {intl.formatMessage({ id: 'O Catálogo Expresso  é um catálogo eletrônico de apresentação dos seus produtos' })}.
      <br />
      {intl.formatMessage({ id: 'Com alto desempenho e facilidade de uso, permite que seus clientes encontrem' })}
      <br />
      {intl.formatMessage({ id: 'os produtos da sua empresa de maneira rápida e eficaz' })}.
    </>
  ),
}, {
  index: 1,
  imgSrc: ImgCatalogoMobile,
  tituloCatalogo: intl.formatMessage({ id: 'CATÁLOGO' }),
  subtituloCatalogo: intl.formatMessage({ id: 'Expresso Mobile' }),
  tituloTextoSlide: intl.formatMessage({ id: 'conheça nossas soluções para celulares e tablets' }),
  textoSlide: (
    <>
      {intl.formatMessage({ id: 'O Catálogo Mobile é um catálogo eletrônico de apresentação dos seus produtos' })}.
      <br />
      {intl.formatMessage({ id: 'Com alto desempenho e facilidade de uso, permite que seus clientes encontrem' })}
      <br />
      {intl.formatMessage({ id: 'os produtos da sua empresa de maneira rápida e eficaz' })}.
    </>
  ),
}, {
  index: 2,
  imgSrc: ImgCatalogoImpresso,
  tituloCatalogo: intl.formatMessage({ id: 'CATÁLOGO' }),
  subtituloCatalogo: intl.formatMessage({ id: 'Impresso' }),
  tituloTextoSlide: intl.formatMessage({ id: 'conheça nosso módulo de impressão' }),
  textoSlide: (
    <>
      {intl.formatMessage({ id: 'A ideia é que o Módulo de Impressão seja uma solução definitiva para geração do' })}
      <br />
      {intl.formatMessage({ id: 'seu Catálogo Impresso. Com apenas um clique você gera um arquivo PDF pronto' })}
      <br />
      {intl.formatMessage({ id: 'para imprimir, disponibilizar no site ou enviar diretamente para o seu cliente' })}.
    </>
  ),
}, {
  index: 3,
  imgSrc: ImgDesenhosTecnicos,
  tituloCatalogo: intl.formatMessage({ id: 'CRIAÇÃO DE ILUSTRAÇÕES' }),
  subtituloCatalogo: intl.formatMessage({ id: 'E DESENHOS TÉCNICOS' }),
  tituloTextoSlide: '.',
  textoSlide: (
    <>
      {intl.formatMessage({ id: 'É um serviço de criação de Ilustrações e Desenhos Técnicos como Figuras de Peças,' })}
      <br />
      {intl.formatMessage({ id: 'Vistas Explodidas de Conjuntos e Manuais Técnicos ou Folhetos. As imagens são' })}
      <br />
      {intl.formatMessage({ id: 'fornecidas em formato de VETOR que permite sua ampliação sem alterar a definição do traçado.' })}.
    </>
  ),
}];
