import React from 'react';
import { FormattedMessage } from 'gatsby-plugin-intl';

export default (
  <>
    <p><FormattedMessage id="Com mais de 15 anos no mercado desenvolvemos mais de 450 catálogos para o setor de autopeças, motopeças, máquinas, ferramentas e bicicletas" />.</p>
    <p><FormattedMessage id="Adquirimos grande experiência e conhecimento técnico para atender as necessidades da sua empresa e valorizar seus produtos quando o assunto é catálogo eletrônico" />.</p>
    <p><FormattedMessage id="A Ideia 2001 tem como prioridade a satisfação dos seus clientes, para isso, possui uma equipe de profissionais altamente eficientes, qualificados e competentes" />.</p>
    <p><FormattedMessage id="Sempre aperfeiçoando soluções práticas e objetivas com o intuito de evoluir junto com seus clientes, a Ideia 2001 vem construindo parcerias de sucesso com pequenas, médias e grandes empresas" />.</p>
    <p><FormattedMessage id="No ramo de catálogos de peças e produtos, somos uma das empresas mais reconhecidas no mercado por aliar tecnologia, facilidade e design, colaborando com o relacionamento da sua companhia e seu usuário final, tornando o catálogo de produtos como um ótimo investimento para as vendas e o crescimento da sua empresa" />.</p>
  </>
);
