import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import { useIntl } from 'gatsby-plugin-intl';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import getConfigSlides from '../../data/configSlidesHome';
import './style.scss';

const renderSlides = ({ intl, data }) => {
  const configSlides = getConfigSlides({ intl });

  return configSlides.map((config) => {
    const file = data.allFile.nodes.find((a) => `${a.name}.${a.extension}` === config.imgSrc);
    const { childImageSharp } = file;

    return (
      <div className={`container slide-custom-home slide-${config.index + 1}`} key={config.index}>
        <div className="container-img">
          <Img fluid={childImageSharp.fluid} loading="eager" className="imgCatalogoInstalado" alt={`${config.tituloCatalogo} ${config.subtituloCatalogo}`} />
        </div>
        <div className="container-titulos">
          <div className="content-titulos">
            <div>
              {
                config.index !== 2 ? (
                  <>
                    <span className="titulo-catalogo">{config.tituloCatalogo}</span>
                    <br /> <span className="subtitulo-catalogo">{config.subtituloCatalogo}</span>
                  </>
                ) : (
                  <>
                    <span className="subtitulo-catalogo" style={{ marginRight: 10 }}>
                      {config.tituloCatalogo}
                    </span>
                    <span className="titulo-catalogo">{config.subtituloCatalogo}</span>
                  </>
                )
              }
            </div>

            <div>
              <span className="textos-slide titulo-texto-slide" style={{ visibility: config.index === 3 ? 'hidden' : '' }}>
                {config.tituloTextoSlide}
              </span>
            </div>

            <div className="textos-slide texto-slide">
              {config.textoSlide}
            </div>

            <div className="container-btn-tenho-interesse">
              <Link to="/contato" className="btn-tenho-interesse">
                {intl.formatMessage({ id: 'Tenho interesse' })} <i className="fas fa-chevron-right"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  });
};

export default () => {
  const intl = useIntl();

  return (
    <StaticQuery
      query={graphql`
        query MyQuery {
          allFile(filter: {relativePath: {in: ["catalogos-instalados-ideia-2001-2-e1563473327620.png", "app-ideia-5-e1563481442645.png", "impresso-3-e1563476481137.png", "desenhos-tecnicos-home.png"]}}) {
            nodes {
              name
              extension
              childImageSharp {
                fluid(maxWidth: 1600, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      `}

      render={data => (
        <section className="slider container-slider-custom-home">
          <Carousel
            showStatus={false}
            showIndicators={false}
            showThumbs={false}
            infiniteLoop
            renderArrowPrev={clickHandler => (
              <button onClick={clickHandler} onKeyDown={clickHandler} className="tp-leftarrow tparrows hesperiden" style={{ top: '50%', transform: 'matrix(1, 0, 0, 1, 20, -20)', left: 0 }} type="button" aria-label={intl.formatMessage({ id: 'Volta slide' })}></button>
            )}
            renderArrowNext={clickHandler => (
              <button onClick={clickHandler} onKeyDown={clickHandler} className="tp-rightarrow tparrows hesperiden" style={{ top: '50%', transform: 'matrix(1, 0, 0, 1, -60, -20)', left: '100%' }} type="button" aria-label={intl.formatMessage({ id: 'Passa slide' })}></button>
            )}
            autoPlay
          >
            {renderSlides({ intl, data })}
          </Carousel>
        </section>
      )}
    />
  );
};
