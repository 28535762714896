export default ({ intl }) => [
  {
    qualidade: intl.formatMessage({ id: 'Aprovado por mais de 300 empresas e 1 milhão de usuários' }),
  },
  {
    qualidade: intl.formatMessage({ id: 'Mais de 50 milhões de acessos por mês' }),
  },
  {
    qualidade: intl.formatMessage({ id: 'Utilizado no mundo inteiro' }),
  },
  {
    qualidade: intl.formatMessage({ id: 'Menor custo no mercado' }),
  },
  {
    qualidade: intl.formatMessage({ id: 'Funciona a partir do Windows XP' }),
  }
];
